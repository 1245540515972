import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(cycle, date, total, paid, due) {
  return { cycle, date, total, paid, due};
}

const rows1 = [
  createData("Cycle #1", "01/01/24 - 15/01/2024", "1,000,00", "1,000,00", 0),
  createData("Cycle #2", "16/01/24 - 29/01/2024", "2,000,00", "1,000,00", "1,000,00")
];

function Billingdetails(props) {
  const [rows, setRows] = useState(rows1);

  useEffect(() => {
    // let i=props.prepareInvoices;
    // setRows(props.items);
  }, [props.items]);
 
  const getTotal=(i)=>{
    let prize;
    prize = i.unit_price* i.quantity;
    return prize;
  }
  const handleInputChange = (e, rowIndex) => {
    const updatedRows = rows.map((row, index) => {
      if (index === rowIndex) {
        return { ...row, unit_price: parseFloat(e.target.value) || 0 };
      }
      return row;
    });
    setRows(updatedRows);

    if(props.onUpdateRow){
      props.onUpdateRow( updatedRows)
    }
  };
  return (
    <div className="w-full">
      <div className="billingdetailstablesection">
          <TableContainer
            component={Paper}
            style={{ borderRadius: "20px" }}
          >
            <div style={{margin: "10px 50px"}}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              style={{ backgroundColor: "transparent !important" }}
            >
              <TableHead>
                <TableCell align="left">Cycle</TableCell>
                <TableCell align="left">Date</TableCell>
                <TableCell align="left">Total</TableCell>
                <TableCell align="left">Paid</TableCell>
                <TableCell align="left">Due</TableCell>
              </TableHead>
              <TableBody>
                {rows&&rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">{row.cycle}</TableCell>
                    <TableCell align="left">{row.date}</TableCell>
                    <TableCell align="left">{row.total}</TableCell>
                    <TableCell align="left">{row.paid}</TableCell>
                    <TableCell align="left">{row.due}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            </div>
          </TableContainer>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mapStateToProps(state) {
  return {
    prepareInvoices: state.procurement.prepareInvoices??{}

  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Billingdetails)
);
