import React, { useState } from "react";
import { Header } from "../../Components/header";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import { userLogin } from "../../services/Actions/client";
import withRouter from "../Router/withRouter";
import { redirect } from "react-router-dom";
import axios from "axios";
import { toast } from 'react-toastify';

import { API } from "../../services/apis";

function Login(props) {
  const [email, setEmail] = useState(0);
  const [password, setPassword] = useState(0);
  // const [form, setForm] = useState({
  //   username: "",
  //   password: "",
  // });
  const [form, setForm] = useState({
    username: "bhuvanaccs@gmail.com",
    password: "TryAdmin456",
  });
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const onUpdateField = (e) => {
    const nextFormState = {
      ...form,
      [e.target.name]: e.target.value,
    };
    setForm(nextFormState);
  };
  const login = (e) => {
    e.preventDefault();
    setSubmitted(true);
    let payload = {
        password: form.password,
        username: form.username,
    };
    console.log("From", form);
    let url = `${API.BASE_URL}api/v1/admin/users/login`;

    axios.post(url, payload)
    .then((res) => {
        let token = res?.data?.token ?? "";

        localStorage.setItem("AdminToken", token);
        props.router.navigate("/dashboard/home/costPrize");
        window.location.reload()
    })
    .catch((error) => {
      toast.error(error?.response?.data??"Login failed")
        setError(error?.response?.data ?? "");
    });
};

  return (
    <div className="w-ful" style={{ overflow: "hidden" }}>
      <Header />
      <div className="row">
      <div className="col-lg-6 content-column">
        <div>
          <h4 className="login-title mb-3">Welcome Back!</h4>
          <p className="login-subtitle">
            Don't have an account yet?{" "}
            <span>
              <a className="login-reachout-txt" href="#">Reach out</a>
            </span>
          </p>
          <form name="form" onSubmit={login}>
            <div className="form-group">
              <div className="input-group">
                <input
                  className={
                    "form-control login-input mb-4" +
                    (submitted && !form.username ? " has-error" : "")
                  }
                  name="username"
                  placeholder="Email Address"
                  value={form.username}
                  onChange={onUpdateField}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="input-group">
                <input
                  type="password"
                  className={
                    "form-control login-input mb-2" +
                    (submitted && !form.password ? " has-error" : "")
                  }
                  name="password"
                  placeholder="Password"
                  value={form.password}
                  onChange={onUpdateField}
                />
              </div>
            </div>
            <div className="checkbox-login">
              <Checkbox className="checkbox-css" />
              <p className="m-0">Remember Me</p>
            </div>
            <div className="mt-2">
              <Button type="submit" className="upload-save-btn">
                Log In
              </Button>
            </div>
          </form>
        </div>
      </div>
      <div class="col-lg-6"><div class="login-image"></div></div>
    </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
