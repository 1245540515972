import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import GreenTriangle from "../../assets/icons/greentriangle.svg";
import RedTriangle from "../../assets/icons/redtriangle.svg";

function Clientcredential(props) {
  const scrollContainerRef = useRef(null);
  const scrollSpeed = 1; 

  const products = [
    { name: "POTATOES", price: "$12.00", changePrice: "$14.00", isPriceUp: true },
    { name: "TOMATOES", price: "$16.00", changePrice: "$24.00", isPriceUp: true },
    { name: "KEERAI", price: "$18.00", changePrice: "$14.00", isPriceUp: false },
    { name: "G.PEPPERS", price: "$18.00", changePrice: "$23.00", isPriceUp: true },
    { name: "ONIONS", price: "$14.00", changePrice: "$9.00", isPriceUp: false },
    { name: "BEANS", price: "$14.00", changePrice: "$9.00", isPriceUp: true },
    { name: "POTATOES", price: "$12.00", changePrice: "$14.00", isPriceUp: true },
    { name: "TOMATOES", price: "$10.00", changePrice: "$8.00", isPriceUp: false },
    { name: "ONIONS", price: "$8.00", changePrice: "$9.00", isPriceUp: true },
  ];

  // Duplicate the product array to simulate an infinite loop
  const extendedProducts = [...products, ...products];

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
  
    let scrollInterval = setInterval(() => {
      if (scrollContainer) {
        scrollContainer.scrollLeft += scrollSpeed;
        if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth / 2) {
          // Smoothly reset to the start without visual break
          scrollContainer.scrollLeft = 0;
        }
      }
    }, 10); // Adjust the interval to control smoothness and speed
  
    return () => clearInterval(scrollInterval); // Clean up on unmount
  }, []);
  

  return (
    <div className="w-full totalproductpricetrendsection">
      <div
        className="scroll-container"
        ref={scrollContainerRef}
        style={{
          display: "flex",
          overflowX: "scroll",
          scrollBehavior: "smooth",
          whiteSpace: "nowrap",
        }}
      >
        {extendedProducts.map((product, index) => (
          <div
            key={index}
            className="col-3 productpricetrendsection text-center"
            style={{ display: "inline-block" }}
          >
            <h4>{product.name}</h4>
            <h4>{product.price}</h4>
            <div className="d-flex justify-content-center">
              <img
                src={product.isPriceUp ? GreenTriangle : RedTriangle}
                alt="triangle-image"
                className={`${product.isPriceUp ? "green-triangle" : "red-triangle"} me-2`}
              />
              <h4 className="mb-0">{product.changePrice}</h4>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Clientcredential)
);