import { Route, Navigate, Outlet  } from 'react-router-dom';


const PrivateRoute = () => {
    const token = isAuthenticated();
  
    return token ?   <Navigate to="/dashboard/home/costPrize" />  : <Navigate to="/landing" />;
  };
  
  export default PrivateRoute;

  const isAuthenticated = () => {
    const token = localStorage.getItem("AdminToken");
    return token?true:false;
  };
  export {isAuthenticated};