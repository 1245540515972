import React from "react";
import {createBrowserRouter, createRoutesFromElements, Route} from "react-router-dom";
import PrivateRoute, {isAuthenticated} from "./privateRoute";
import HomeCostPrize from "../Auth/homeCostPrize";
import HomeSellingPrize from "../Auth/homeSellingPrize";
import HomeOrders from "../Auth/homeOrders";
import HomeInvoice from "../Auth/homeInvoice";
import Clients from "../Auth/clients";
import Clientcredential from "../Auth/clientcredential";
import Clientinvoice from "../Auth/clientinvoice";
import Clientinvoicedetails from "../Auth/clientinvoicedetails";
import Login from "../Auth/login";
import Dashboard from "../Auth/dashboard";
import HomeLaunch from "../Auth/homeLaunch";
import Landing from "../Auth/landing";
import NewProduct from "../Product/newProduct";
import Products from "../Product/products";
import Billings from "../Auth/billings";
import Insights from "../Auth/insights";
// import {} 
const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route path="/" element={<PrivateRoute />}>
        </Route>
       
        <Route path="/" element={<NewProduct />} />
        <Route path="/login" element={<Login />} />
        <Route path="/landing" element={<Landing />} />

        <Route path="/dashboard" element={<Dashboard />} >
        {isAuthenticated()&&<Route path="home/:tab" element={<HomeLaunch />} /> }
        {isAuthenticated()&&<Route path="clients" element={<Clients />} />}
        {isAuthenticated()&&<Route path="clientcredential" element={<Clientcredential />} />}
       {isAuthenticated()&& <Route path="clientinvoice" element={<Clientinvoice />} />}
       {isAuthenticated()&& <Route path="clientinvoicedetails/:id" element={<Clientinvoicedetails />} />}
       {isAuthenticated()&& <Route path="product/:type/:id?" element={<NewProduct />} />}
       {isAuthenticated()&& <Route path="products" element={<Products />} />}
       {isAuthenticated()&& <Route path="billings" element={<Billings />} />}
       {isAuthenticated()&& <Route path="insights" element={<Insights />} />}
        </Route>
        <Route path="*" element={<h1>Page not found</h1>} />
      </Route>
    )
  );
  export default router;