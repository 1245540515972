
import React, { useEffect } from "react";
import {
  RouterProvider
} from "react-router-dom";
import router from "./Pages/Router/router";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./App.css"
import "./Order.css"
import "./Invoice.css"
import "./Clients.css"
import "./Clientdetails.css"
import "./Responsive.css"
import "./login.css"
import 'react-datepicker/dist/react-datepicker.css';

const App = () => {

  return (
    <div>
     <RouterProvider
    router={router}
    fallbackElement={<div />}
  />
    <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default App;
