import React from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import { MainHeader } from "../../Components/header";
import Sidebar from "../../Components/sidebar";
import Clientinvoicedetails from "./clientinvoicedetails.js";

function Clientinvoice(props) {
  return (
    <div className="w-full">
      {/* <div className="headerandsidebar">
        <MainHeader />
        <Sidebar />
      </div> */}
      <div className="main-container clientdetailssection">
        <div className="container">
          <div className="row">            
            <div className="productssection">
              <Clientinvoicedetails />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Clientinvoice)
);