import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import Billingdetails from "./billingdetails";
import Form from "react-bootstrap/Form";

function createData(name, details) {
  return { name, details };
}

const initialRows = [
  createData("INVOICE DATE", ""),
  createData("CLIENT NAME", ""),
  createData("COMPANY NAME", ""),
  createData("GST ID", ""),
  createData("ORDER ID", ""),
];

function Billings(props) {
  const [rows, setRows] = useState(initialRows);

  useEffect(() => {
    let i = props.prepareInvoices;
    let row = [
      // createData("INVOICE DATE", ""),
      createData("CLIENT NAME", i?.client_name ?? ""),
      createData("COMPANY NAME", i?.company_name ?? ""),
      createData("GST ID", i?.gst_id ?? ""),
      createData("ORDER ID", i?.order?.order_number ?? ""),
    ];
    setRows(row);
  }, [props]);
  return (
    <div className="w-full">
      <div className="main-container">
        <div className="container">
          <div className="row billingtablesection">
            <div className="col-12">
              <h3 class="primaryheader">Billing Cycle </h3>
            </div>
            <div className="col-12 d-flex">
              <div className="col-9 d-flex billingfiltersection">
                <div className="col d-flex align-items-center px-3">
                  <Form.Select aria-label="Default select example" className="dropdownsection">
                    <option>CRISP DOSA</option>
                    <option value="1">CRISP DOSA 1</option>
                    <option value="2">CRISP DOSA 2</option>
                    <option value="3">CRISP DOSA 3</option>
                  </Form.Select>
                </div>
                <div className="col d-flex align-items-center px-3">
                  <h4 className="me-4">FROM</h4>
                  <Form.Control type="text" className="inputsection" />
                </div>
                <div className="col d-flex align-items-center px-3">
                  <h4 className="me-4">TO</h4>
                  <Form.Control type="text" className="inputsection" />
                </div>
              </div>
              <div className="col-3 d-flex justify-content-end align-items-center">
                <button className="nav-button-section" style={{lineHeight: "2"}}>
                  CREATE A BILLING CYCLE
                </button>
              </div>
            </div>
            <div className="col-12">
              <Billingdetails />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mapStateToProps(state) {
  return {
    prepareInvoices: state.procurement.prepareInvoices ?? {},
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Billings)
);
