import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import { MainHeader } from "../../Components/header";
import Sidebar from "../../Components/sidebar";
import Navbar from "../../Components/navbar";
import HomeCostPrize from "./homeCostPrize";
import HomeOrders from "./homeOrders";
import HomeSellingPrize from "./homeSellingPrize";
import HomeInvoice from "./homeInvoice";
function HomeLaunch(props) {
  const [value, setValue] = React.useState("");

  useEffect(() => {
    console.log("Props",props);
    setValue(props.router.params.tab);
  }, [props]);

    return (
        <div className="w-full">
        {/* <div className="headerandsidebar">
          <MainHeader />
          <Sidebar />        
        </div> */}
        <Navbar />
        {value=="costPrize"?<HomeCostPrize />: value=="sellingPrize"?<HomeSellingPrize />: value=="orders"? <HomeOrders />: <HomeInvoice />}
        </div>
    );
}

function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  
  function mapStateToProps(state) {
    return {
    //   sellingPrices: state.procurement.sellingPrices??{}
  
    };
  }
  
  export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(HomeLaunch)
  );